export default {
  mounted (el, binding) {
    el.setAttribute('title', binding.value);
    el.setAttribute('data-bs-toggle', 'tooltip');

    if (!el.getAttribute('data-bs-placement')) {
      el.setAttribute('data-bs-placement', 'top');
    }
  },
};
