/* eslint-disable vue/no-reserved-component-names */
import * as Sentry from '@sentry/vue';
import SimpleAnalytics from 'simple-analytics-vue';
import { createApp, h } from 'vue';
import { createInertiaApp, Head, Link, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

/* Directives */
import GlobalDirectives from '@js-inertia/Directives';

/* Layout */
import DefaultLayout from '@layouts/DefaultLayout';

/* Plugins */
import PluginTooltips from '@js-inertia/Plugins/Tooltips';

/* State */
import { createPinia } from 'pinia';

/* Create App */
createInertiaApp({
  resolve: async (name) => {
    const page = (
      await resolvePageComponent(
        `./Pages/${name}.vue`,
        import.meta.glob('./Pages/**/*.vue'),
      )
    ).default;

    if (page.layout === undefined) {
      page.layout = DefaultLayout;
    }

    return page;
  },

  setup ({ el, App, props, plugin }) {
    const pinia = createPinia();
    const app = createApp({
      render: () => h(App, props),
    }).use(plugin);

    /* Init Sentry */
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      integrations: [
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    /* Register Components */
    app.component('Head', Head);
    app.component('Link', Link);

    /* Register Directives */
    app.use(GlobalDirectives);

    /* Register Plugins */
    app.config.globalProperties.route = route;
    app.config.globalProperties.$tooltips = PluginTooltips;

    /* Register State */
    app.use(pinia);

    /* Register Analytics */
    app.use(SimpleAnalytics, {
      domain: 'sa.dogs.ie',
      skip: process.env.NODE_ENV !== 'production' || window.location.pathname.startsWith('/admin'),
    });

    /* Add User Impersonation Support */
    const userProxyId = document.querySelector('meta[name="user-proxy-id"]')?.content;
    if (userProxyId) {
      router.on('before', (event) => {
        event.detail.visit.headers['X-USER-PROXY-ID'] = userProxyId;
      });
    }

    /* Mount App */
    app.mount(el);
  },

  title: (title) => `${title} - ${import.meta.env.VITE_APP_NAME}`,
});
